import { Component, OnInit } from '@angular/core';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-env-alert',
  templateUrl: './env-alert.component.html',
  styleUrls: ['./env-alert.component.scss'],
})
export class EnvAlertComponent implements OnInit {
  showAlert: boolean;

  constructor() {
    this.showAlert = !environment.production;
  }

  ngOnInit(): void {}
}
