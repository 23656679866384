import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { DialogContent } from '../models/dialog-content';

@Component({
  selector: 'app-response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.scss'],
})
export class ResponseDialogComponent implements OnInit {
  dialog: DialogContent = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private api: ApiService) {
    this.setDialogMessage(data);
  }

  ngOnInit(): void {}

  private setDialogMessage(status: string) {
    this.api.getDialogContents().subscribe((data: { [x: string]: any }) => {
      this.dialog = data[status] || data['422'];
    });
  }
}
