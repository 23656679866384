import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { CTALink } from '../models/cta-link';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'link-generator-form',
  templateUrl: './link-generator-form.component.html',
  styleUrls: ['./link-generator-form.component.scss'],
})
export class LinkGeneratorFormComponent implements OnInit {
  products: any;
  formGroup: FormGroup;
  prod: boolean;
  ibanIsRequired: boolean = false;
  showForm: boolean = true;
  isLoading: boolean = false;

  constructor(private api: ApiService, public dialog: MatDialog) {
    this.formGroup = new FormGroup({
      selectedProduct: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@(sardex|sardexpay).net$'),
      ]),
      iban: new FormControl('', [
        Validators.pattern('^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$'),
      ]),
    });
    this.prod = environment.production;
  }

  ngOnInit(): void {
    this.getProducts();
  }

  private _getProductByName(productName: string) {
    return this.products.find((product: any) => product.name === productName);
  }

  getProducts() {
    this.api.getProducts().subscribe((data: any) => {
      this.products = data.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    });
  }

  getLink() {
    this.isLoading = true;

    let ctaLink: CTALink = {
      productName: this.formGroup.get('selectedProduct')?.value,
      email: this.formGroup.get('email')?.value,
    };

    // Send IBAN only if the it's filled
    const IBAN = this.formGroup.get('iban')?.value;
    if (IBAN?.length) {
      ctaLink = {
        IBAN,
        ...ctaLink,
      };
    }

    this.api.generateLink(ctaLink).subscribe(
      (response) => {
        this.dialog.open(ResponseDialogComponent, { data: '200' });
        this.resetFormGroup();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;

        if (
          err.error === 'IBAN non valido per la rateizzazione' ||
          err.error === 'IBAN non valido per il pagamento istantaneo'
        ) {
          this.formGroup.controls.iban.setErrors({
            validationMessage: err.error,
          });
        }

        this.dialog.open(ResponseDialogComponent, { data: err.status });
      },
    );
  }

  resetFormGroup() {
    this.formGroup.reset();
  }

  help() {
    this.dialog.open(ResponseDialogComponent, {
      data: 'help',
      maxWidth: '730px',
      width: '80vw',
    });
  }
}
