<app-env-alert></app-env-alert>
<div class="app-container">
  <div>
    <div class="logo-container">
      <img src="assets/images/logo_sardex.svg" />
      <div class="page-title">Onboarding Link Generator</div>
    </div>
    <link-generator-form></link-generator-form>
  </div>
</div>
