import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CTALink } from './models/cta-link';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}
  getProducts() {
    const url = `${environment.baseUrl}${environment.productsUrl}${environment.product}`; // force fetch from json atm
    return this.http.get(url);
  }
  getDialogContents():any {
    const url = `${environment.assetUrl}${environment.dialogMessages}`; // force fetch from json atm
    return this.http.get(url);
  }
  generateLink(body: CTALink) {
    const url = `${environment.baseUrl}${environment.linkGenerator}`;
    return this.http.post(url, body);
  }
}
