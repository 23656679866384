<div class="link-generator-form">
  <form [formGroup]="formGroup" novalidate *ngIf="showForm">
    <div class="form-container">
      <mat-form-field appearance="fill">
        <mat-label>Piano tariffario</mat-label>
        <mat-select
          required
          formControlName="selectedProduct"
          formControlName="selectedProduct"
          name="selectedProduct"
        >
          <mat-option [value]="product.name" *ngFor="let product of products">
            {{ product.name }}
          </mat-option>
        </mat-select>
        <mat-hint
          >Seleziona il piano tariffario da proporre in vendita al prospect</mat-hint
        >
        <mat-error *ngIf="formGroup.invalid"> Specificare un prodotto </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input
          matInput
          #input
          type="email"
          (keyup.enter)="getLink()"
          placeholder="mail@example.com"
          name="email"
          formControlName="email"
          required
        />
        <mat-hint>Inserisci il tuo indirizzo email aziendale</mat-hint>
        <mat-error *ngIf="formGroup.invalid"> Inserire una mail valida </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>IBAN</mat-label>
        <input
          matInput
          #input
          (keyup.enter)="getLink()"
          placeholder="IT00A0000000000000000000000"
          name="iban"
          formControlName="iban"
          [required]="ibanIsRequired"
        />
        <mat-hint
          >Inserisci l'IBAN che l'utente utilizzerà per il pagamento Sepa Istantaneo o
          Rateale</mat-hint
        >
        <mat-error *ngIf="formGroup.invalid">
          {{
            formGroup.controls.iban.errors?.validationMessage || 'Inserire un IBAN valido'
          }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="submit-container">
    <button
      mat-flat-button
      color="primary"
      (click)="getLink()"
      [disabled]="formGroup.invalid || isLoading"
    >
      <ng-container *ngIf="!isLoading"> GENERA LINK </ng-container>
      <ng-container *ngIf="isLoading">
        <mat-spinner diameter="20"></mat-spinner>
      </ng-container>
    </button>
    <div class="need-help pointer" (click)="help()">Hai bisogno di aiuto?</div>
  </div>
</div>
