export const environment = {
  production: false,
  baseUrl: 'https://onboarding-backend.sardlab.io/api/',
  assetUrl: 'assets/json/',
  initializeUrl: 'initialize/',
  productsUrl: 'listproducts/',
  product: 'sardex',
  dialogMessages: 'dialog-messages.json',
  linkGenerator: 'linkgenerator',
};
